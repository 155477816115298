import React from "react";
import { Helmet } from "react-helmet-async";
import TitleBar from "../Common/TitleBar";
import MbTitleBar from "../Common/MbTitleBar";

const ContactUs = () => {
  return (
    <>
    <Helmet>
      <title>Contact Us | Readkar</title>
    </Helmet>
    <div className="h-full flex flex-col">
        <div className="hidden md:flex mb-10">
          <TitleBar />
        </div>
        <div className="flex md:hidden h-20">
          <MbTitleBar />
        </div>
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg overflow-auto flex flex-grow flex-col ">
          
      <div className="bg-gray-100 min-h-screen flex items-center justify-center px-6 py-10">
        <div className="bg-white shadow-lg rounded-lg max-w-4xl w-full p-8">
          <h1 className="text-3xl font-bold text-gray-800 text-center mb-6">
            Contact Us
          </h1>
          <p className="text-center text-gray-600 mb-10">
            We’re here to help! Whether you have a question, need support, or
            want to share your feedback, feel free to reach out to us.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-center">
            <div className="flex flex-col items-center">
              <div className="bg-blue-500 text-white w-16 h-16 flex items-center justify-center rounded-full mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 10h11M9 21H5a2 2 0 01-2-2V9a2 2 0 012-2h4a2 2 0 012 2v10a2 2 0 01-2 2zm12-9V7a2 2 0 00-2-2h-2a2 2 0 00-2 2v5a2 2 0 002 2h2a2 2 0 002-2zm-6 3v3m3 0v3m3 0v3"
                  />
                </svg>
              </div>
              <h2 className="text-lg font-medium text-gray-800">
                Phone Support
              </h2>
              <p className="text-gray-600 mt-2">+91-8956367644</p>
              <p className="text-gray-500">9:00 AM - 9:00 PM</p>
            </div>

            <div className="flex flex-col items-center">
              <div className="bg-green-500 text-white w-16 h-16 flex items-center justify-center rounded-full mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M16 12h1v1a5 5 0 005-5V7a5 5 0 00-5-5h-2a5 5 0 00-5 5v1a5 5 0 005 5zM9 14H7a5 5 0 00-5 5v1a5 5 0 005 5h2a5 5 0 005-5v-1a5 5 0 00-5-5z"
                  />
                </svg>
              </div>
              <h2 className="text-lg font-medium text-gray-800">Email Us</h2>
              <p className="text-gray-600 mt-2">support@readkar.com</p>
            </div>

            <div className="flex flex-col items-center">
              <div className="bg-red-500 text-white w-16 h-16 flex items-center justify-center rounded-full mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8l7.89 5.26a4 4 0 004.22 0L21 8m-9 4l2 4m2-4v6a2 2 0 002 2h4a2 2 0 002-2v-6m-9 4H4a2 2 0 01-2-2V4a2 2 0 012-2h16a2 2 0 012 2v4"
                  />
                </svg>
              </div>
              <h2 className="text-lg font-medium text-gray-800">Visit Us</h2>
              <p className="text-gray-600 mt-2">
                1404, Tower A, You 57 Society, Phase 3, Hinjewadi, Pune 411057
              </p>
            </div>
          </div>

          <p className="text-center text-gray-500 mt-10">
            ANIVEDAM TECHNOLOGIES PVT LTD
          </p>
        </div>
      </div>
      </div>
      </div>
    </>
  );
};

export default ContactUs;
