import React from "react";
import { Helmet } from "react-helmet-async";
import TitleBar from "../Common/TitleBar";
import MbTitleBar from "../Common/MbTitleBar";

const TermsOfUse = () => {
  return (
    <>
      <Helmet>
        <title>Terms of Use | Readkar</title>
      </Helmet>
      <div className="h-full flex flex-col">
        <div className="hidden md:flex mb-10">
          <TitleBar />
        </div>
        <div className="flex md:hidden h-20">
          <MbTitleBar />
        </div>
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg overflow-auto flex flex-grow flex-col ">
          <h1 className="text-3xl font-bold text-center mb-6">
            Terms of Service
          </h1>
          <p className="text-gray-700 text-sm mb-4">
            <strong>Effective Date:</strong> 12th Dec 2024
          </p>
          <p className="text-gray-700 mb-4">
            This Terms of Service ("Agreement") constitutes a legally binding
            agreement between you ("User," "you," or "your") and ANIVEDAM
            TECHNOLOGIES PVT LTD ("Company," "we," "us," or "our") governing
            your use of the platform and services provided by Readkar. By
            accessing or using any part of the Readkar platform, you confirm
            that you have read, understood, and agree to be bound by this
            Agreement.
          </p>
          <h2 className="text-2xl font-semibold mb-4">1. Services Offered</h2>
          <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
            <li>A subscription-based book renting service.</li>
            <li>Chat with Character AI for user engagement and support.</li>
            <li>A community platform for book enthusiasts.</li>
          </ul>
          <h2 className="text-2xl font-semibold mb-4">2. Eligibility</h2>
          <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
            <li>There is no minimum age requirement to use the platform.</li>
            <li>
              Access to the book renting service is geographically limited to
              users located in India.
            </li>
          </ul>
          <h2 className="text-2xl font-semibold mb-4">3. Account Creation</h2>
          <p className="text-gray-700 mb-6">
            To access the services, Users must create an account by providing
            accurate and complete information, including their name, email, and
            phone number. Users are solely responsible for safeguarding their
            account credentials and any actions performed under their account.
          </p>
          <h2 className="text-2xl font-semibold mb-4">4. Subscription Terms</h2>
          <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
            <li>
              All subscription fees paid to the Company are final and
              non-refundable.
            </li>
            <li>
              Subscriptions are billed monthly. Cancellations will take effect
              at the end of the current billing cycle, after which no further
              charges will be incurred.
            </li>
            <li>
              Users may retain rented books indefinitely, provided their
              subscription remains active and in good standing. Subscription
              renewals will continue automatically until cancellation is
              initiated by the User.
            </li>
          </ul>
          <h2 className="text-2xl font-semibold mb-4">
            5. Book Renting Service
          </h2>
          <p className="text-gray-700 mb-6">
            Users may retain books rented through Readkar for as long as their
            subscription is active. Additional fees may apply for damage or loss
            of rented books. Book recommendations are customized based on the
            user’s rental history and indicated preferences.
          </p>
          <h2 className="text-2xl font-semibold mb-4">
            6. Shipping, Delivery, and Pickup
          </h2>
          <p className="text-gray-700 mb-4">
            <strong>Delivery:</strong> Readkar provides book delivery services
            exclusively within Pune. The estimated delivery times are as
            follows:
          </p>
          <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
            <li>
              If the book is available: 24 hours. However, delivery may be
              delayed due to unforeseen circumstances.
            </li>
            <li>
              If the book is not available: 48 hours. However, delivery may be
              delayed due to unforeseen circumstances.
            </li>
          </ul>
          <p className="text-gray-700 mb-6">
            All delivery costs are included in the subscription fees.
          </p>
          <p className="text-gray-700 mb-6">
            <strong>Pickup:</strong> Readkar does not allow users to pick up
            books from its location. Once a user marks a book for return,
            Readkar will arrange for the book to be picked up from the user’s
            doorstep. All pickup costs are included in the subscription fees.
          </p>
          <h2 className="text-2xl font-semibold mb-4">
            7. Chat with Character AI
          </h2>
          <p className="text-gray-700 mb-6">
            The Chat with Character AI feature is provided to enhance User
            interaction. Conversations are encrypted and reviewed exclusively
            for service improvement purposes. Misuse of this feature for illegal
            or harmful activities is strictly prohibited.
          </p>
          <h2 className="text-2xl font-semibold mb-4">
            8. Content and API Usage
          </h2>
          <p className="text-gray-700 mb-6">
            The book search functionality utilizes the Google Books API. Results
            from this API are provided "as is," and the Company assumes no
            liability for their accuracy or completeness.
          </p>
          <h2 className="text-2xl font-semibold mb-4">9. Prohibited Conduct</h2>
          <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
            <li>Violating applicable laws or regulations.</li>
            <li>Engaging in fraudulent, abusive, or disruptive behavior.</li>
            <li>Compromising the security or functionality of the platform.</li>
          </ul>
          <h2 className="text-2xl font-semibold mb-4">
            10. Termination of Account
          </h2>
          <p className="text-gray-700 mb-6">
            The Company reserves the right to suspend or permanently terminate
            any user account that violates this Agreement or poses a risk to the
            platform’s integrity.
          </p>
          <h2 className="text-2xl font-semibold mb-4">
            11. Disclaimers and Limitations of Liability
          </h2>
          <p className="text-gray-700 mb-6">
            The Company makes no guarantees regarding uninterrupted access to
            the platform or error-free operation. The Company disclaims
            responsibility for inaccuracies in third-party content, including
            results derived from the Google Books API.
          </p>
          <h2 className="text-2xl font-semibold mb-4">12. Privacy Policy</h2>
          <p className="text-gray-700 mb-6">
            By using the platform, Users acknowledge and consent to the
            collection, use, and sharing of their information as described in
            the Privacy Policy.
          </p>
          <h2 className="text-2xl font-semibold mb-4">
            13. Links to Other Pages
          </h2>
          <p className="text-gray-700 mb-6">
            The platform may include links to third-party websites or services.
            The Company disclaims any responsibility for the content, privacy
            policies, or practices of these external entities.
          </p>
          <h2 className="text-2xl font-semibold mb-4">
            14. Dispute Resolution
          </h2>
          <p className="text-gray-700 mb-6">
            All disputes arising out of or in connection with this Agreement
            shall be subject to arbitration or adjudication in accordance with
            the laws of India.
          </p>
          <h2 className="text-2xl font-semibold mb-4">
            15. Modifications to Terms
          </h2>
          <p className="text-gray-700 mb-6">
            The Company reserves the right to modify this Agreement at any time.
            Users will be notified of changes via email, and continued use of
            the platform constitutes acceptance of the updated terms.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsOfUse;
