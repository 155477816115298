import React from "react";
import { Helmet } from "react-helmet-async";
import MbTitleBar from "../Common/MbTitleBar";
import TitleBar from "../Common/TitleBar";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Readkar</title>
      </Helmet>
      <div className="h-full flex flex-col">
        <div className="hidden md:flex mb-10">
          <TitleBar />
        </div>
        <div className="flex md:hidden h-20">
          <MbTitleBar />
        </div>
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg overflow-auto flex flex-grow flex-col ">
          <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
            <h1 className="text-3xl font-bold text-center mb-6">
              Privacy Policy
            </h1>
            <p className="text-gray-700 text-sm mb-4">
              <strong>Last Updated:</strong> 12th Dec 2024
            </p>
            <p className="text-gray-700 mb-4">
              This Privacy Policy ("Privacy Policy") governs your use of the
              Readkar platform, operated by ANIVEDAM TECHNOLOGIES PVT LTD
              ("Company," "we," "us," or "our"). It applies to the use of our
              website, mobile applications (collectively referred to as the
              "Platform"), and the services provided therein (the "Services").
            </p>
            <p className="text-gray-700 mb-4">
              By accessing or using the Platform, you confirm that you have
              read, understood, and consented to the collection, use, and
              disclosure of your information as outlined in this Privacy Policy.
            </p>
            <h2 className="text-2xl font-semibold mb-4">
              1. Information We Collect
            </h2>
            <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
              <li>
                <strong>Account Information:</strong> Name, email address, and
                phone number.
              </li>
              <li>
                <strong>Activity Data:</strong> Rental history and book
                preferences.
              </li>
              <li>
                <strong>Chat Data:</strong> Encrypted conversations with
                Character AI.
              </li>
            </ul>
            <h2 className="text-2xl font-semibold mb-4">
              2. Use of Collected Data
            </h2>
            <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
              <li>Facilitate account creation and provide Platform access.</li>
              <li>
                Deliver personalized recommendations and enhance the Platform’s
                usability.
              </li>
              <li>
                Improve service functionality and resolve technical issues.
              </li>
            </ul>
            <h2 className="text-2xl font-semibold mb-4">3. Sharing of Data</h2>
            <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
              <li>
                Data is shared with third-party payment processors for
                transaction facilitation.
              </li>
            </ul>
            <h2 className="text-2xl font-semibold mb-4">
              4. Security Measures
            </h2>
            <p className="text-gray-700 mb-6">
              We employ secure servers, encryption protocols, and regular audits
              to safeguard your data against unauthorized access and breaches.
            </p>
            <h2 className="text-2xl font-semibold mb-4">5. User Rights</h2>
            <p className="text-gray-700 mb-6">
              Users may access and update their details via their profile page.
              Requests for data modifications or removal should be sent to our
              support email.
            </p>
            <h2 className="text-2xl font-semibold mb-4">
              6. Children’s Privacy
            </h2>
            <p className="text-gray-700 mb-6">
              Our platform is accessible to users of all ages. We do not
              knowingly collect information from children under 13 without
              parental consent.
            </p>
            <h2 className="text-2xl font-semibold mb-4">7. Policy Updates</h2>
            <p className="text-gray-700 mb-6">
              This Privacy Policy is subject to updates. Users will be notified
              of material changes via email or platform notifications.
            </p>
            <h2 className="text-2xl font-semibold mb-4">8. Jurisdiction</h2>
            <p className="text-gray-700 mb-6">
              This Privacy Policy is governed by the laws of India.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
